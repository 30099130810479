import I from '../types.ts';

const getCorporation: I['getCorporation'] = function (notAdmin = false) {
    const { user } = this.state;

    if (!user) {
        return null;
    }

    if (notAdmin === true) {
        return (
            user.corporationsInfo.find((item) => item._id === this.getNotAdminCorporation()) || null
        );
    }

    return (
        user.corporationsInfo.find((item) =>
            user.idOfCurrentCorporation === 'admin'
                ? item.isAdmin
                : item._id === user.idOfCurrentCorporation,
        ) || null
    );
};

export default getCorporation;
