import I from '../types.ts';

const checkList: I['checkList'] = function () {
    const { user } = this.state;

    if (!user) {
        return false;
    }

    return user.corporations.length > 1 || !user.corporationsInfo.find((item) => item.isAdmin);
};

export default checkList;
