import clearPhone from '@functions/clearPhone.ts';
import getUserName from '@functions/getUserName.ts';

import I from '../types.ts';

const mountHandler: I['mountHandler'] = async function () {
    const { chatEditPopup } = this.props;
    const { chat } = chatEditPopup;

    const model: I['state']['model'] = {
        _id: 'chat',
        tags: [],
    };

    if (chat) {
        model._id = chat._id;
        model.tags = chat.tagsInfo;

        if (chat.anyInfo?.name) {
            model.name = chat.anyInfo?.name;
        }

        if (chat.anyInfo?.phone) {
            model.phone = clearPhone(chat.anyInfo?.phone);
        }

        if (chat.anyInfo?.userId) {
            model.userId = chat.anyInfo?.userId;
            model.executorName = chat.anyInfo?.name;
        }

        if (chat.responsible) {
            model.responsibleId = chat.responsible;

            if (chat.infoResponsible) {
                model.responsibleName = getUserName({ user: chat.infoResponsible });
            }
        }
    }

    this.init({ fields: model });
};

export default mountHandler;
