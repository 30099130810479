import { CursorActionsItemT } from '@global/types.ts';

import I from '../types.ts';

const getMoreActions: I['getMoreActions'] = function () {
    const { user } = this.state;
    const model = this.state.model!;
    const actions: CursorActionsItemT[] = [];

    if (user?.idOfCurrentCorporation === 'admin') {
        if (0) {
            actions.push({
                name: 'clearSmsTry',
                key: 'again',
                text: 'Сбросить смс-попытки',
                onClick: this.clearSmsTryHandler.bind(this),
            });
        }

        if (model.organization === 'SMZ') {
            if (!model.isJurStatusActive) {
                if (!model.isJurStatusProcess) {
                    actions.push({
                        name: 'addFns',
                        key: 'again',
                        text: 'Подключить ФНС',
                        onClick: this.fnsHandler.bind(this, { isAdd: true }),
                    });
                }

                if (model.isJurStatusProcess) {
                    actions.push({
                        name: 'checkStatusFns',
                        key: 'again',
                        text: 'Проверить ФНС',
                        onClick: this.fnsHandler.bind(this, { isCheck: true }),
                    });
                }
            }

            if (model.isJurStatusActive) {
                actions.push({
                    name: 'checkFns',
                    key: 'again',
                    text: 'Проверить данные ФНС',
                    onClick: this.checkFns.bind(this),
                });
            }
        }

        if (model.organization === 'FL') {
            actions.push({
                name: 'findInn',
                key: 'open',
                text: 'Найти ИНН',
                onClick: this.findInn.bind(this),
            });
        }
    }

    return actions;
};

export default getMoreActions;
