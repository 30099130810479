import axios from 'axios';
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import getHeaders from '@functions/getHeaders';

import handlerPopup from '../../../functions/app/handlerPopup';
import getEndText from '../../../functions/getEndText';

import exportItems from '../../../requests/exportItems';
import Button from '../../Button.jsx';
import Checkbox from '../../Checkbox.jsx';

class ExportPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerFields = this.handlerFields.bind(this);
        this.export = this.export.bind(this);

        this.parent = React.createRef();
    }

    getTitle() {
        const { type } = this.props;

        if (type === 'executors') {
            return 'Экспорт исполнителей';
        }

        if (type === 'paysGroups') {
            return 'Экспорт реестров актов';
        }

        if (type === 'pays') {
            return 'Экспорт актов';
        }

        if (type === 'operations') {
            return 'Экспорт операций';
        }

        if (type === 'contracts') {
            return 'Экспорт договоров';
        }

        return 'Экспорт';
    }

    getFileName() {
        const { type, counter = 0 } = this.props;
        let title;

        if (type === 'executors') {
            title = 'Исполнители';
        }

        if (type === 'joins') {
            title = 'Подключения';
        }

        if (type === 'paysGroups') {
            title = 'Реестры актов';
        }

        if (type === 'pays') {
            title = 'Акты';
        }

        if (type === 'operations') {
            title = 'Операции';
        }

        if (type === 'contracts') {
            title = 'Договоры';
        }

        return `${title} (${counter})`;
    }

    handlerFields({ name, value }) {
        this.setState((state) => {
            const newState = { ...state };
            const fields = { ...newState.fields };

            fields[name] = value;

            newState.fields = fields;

            return newState;
        });
    }

    setFields() {
        const { exportInfo } = this.state;

        const fields = {};

        exportInfo?.order.forEach((colName) => {
            fields[colName] = true;
        });

        this.setState({ fields });
    }

    checkDisabled() {
        const { fields = {} } = this.state;

        return Object.keys(fields).every((key) => fields[key] === false);
    }

    handlerLoadingKey(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    export() {
        if (!this.checkDisabled()) {
            const { fields } = this.state;
            const { filterQuery = [], type } = this.props;
            let strQuery = '';

            filterQuery.forEach(({ key, value }) => {
                strQuery += `${key}=${value}&`;
            });

            this.handlerLoadingKey(true).then(() => {
                exportItems({
                    query: strQuery,
                    type,
                    fields: Object.keys(fields).filter((key) => fields[key] === true),
                    inPopup: true,
                    filename: this.getFileName(),
                }).then(
                    () => {
                        this.handlerLoadingKey(null);
                    },
                    () => null,
                );
            });
        }
    }

    checkScroll() {
        const inner = this.parent.current.querySelector('.exportPopup__contentInner');
        const { offsetHeight, scrollHeight } = inner;
        const resultScrollHeight = scrollHeight - offsetHeight;

        if (resultScrollHeight !== this.resultScrollHeight) {
            this.resultScrollHeight = resultScrollHeight;

            this.setState({ withScroll: resultScrollHeight > 0 });
        }
    }

    async getExport() {
        const { type } = this.props;
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/export?type=${type}`, {
                headers: getHeaders(),
            });
            const { success, data } = response.data;

            if (success) {
                this.setState({ exportInfo: data }, () => {
                    this.setFields();
                    this.checkScroll();
                });
            }
        } catch (error) {}
    }

    componentDidMount() {
        this.getExport();
    }

    componentDidUpdate() {
        this.checkScroll();
    }

    render() {
        const { fields, loadingKey, withScroll, exportInfo } = this.state;
        const { counter = 0 } = this.props;

        const titleText = `${counter} ${getEndText(counter, ['запись', 'записи', 'записей'])}`;

        return (
            <div ref={this.parent} className="exportPopup _col">
                <div className="exportPopup__inner">
                    <div className="exportPopup__head">
                        <div className="exportPopup__title">
                            <b>{this.getTitle()}</b>: <span>{titleText}</span>
                        </div>
                        <p className="exportPopup__description">
                            Выберите необходимые колонки, которые
                            <br />
                            будут экспортированы из документа
                        </p>
                    </div>
                    <div className={`exportPopup__content ${withScroll ? '_withScroll' : ''}`}>
                        <div className="exportPopup__contentInner">
                            {exportInfo?.order.map((colName) => {
                                const col = exportInfo?.info[colName];

                                return (
                                    <div className="exportPopup__contentItem" key={colName}>
                                        <label
                                            className="exportPopup__contentItemInner _row _click"
                                            htmlFor={colName}
                                        >
                                            <div className="exportPopup__contentItemCheckbox">
                                                <Checkbox
                                                    className="_square _green _export"
                                                    name={colName}
                                                    value={fields?.[colName]}
                                                    handler={this.handlerFields}
                                                    id={colName}
                                                    icon="done-3"
                                                />
                                            </div>
                                            <div className="exportPopup__contentItemName">
                                                {col?.content}
                                            </div>
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="exportPopup__foot _row">
                        <div className="exportPopup__footButton _cancel">
                            <Button
                                className="_mediumSize _cancelEmpty"
                                onClick={() => {
                                    handlerPopup({
                                        name: 'exportPopup',
                                        isShow: false,
                                    });
                                }}
                            >
                                Отменить
                            </Button>
                        </div>
                        <div className="exportPopup__footButton">
                            <Button
                                className="_mediumSize _mainNotBorder"
                                isDisabled={this.checkDisabled()}
                                onClick={this.export}
                                showLoader={loadingKey}
                            >
                                Выгрузить {titleText}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ExportPopup);

ExportPopup.propTypes = {
    counter: PropTypes.number,
    type: PropTypes.string,
    filterQuery: PropTypes.object,
};
