import JoinDocT from '@global/models/JoinDoc';

const joinDocMain = {
    title: 'Данные',
    description: (model: JoinDocT) => model?.name,
    fieldsOrder: ['name', 'filesCounter', 'countries'],
    fields: {
        name: {
            support: 'Название',
            type: 'input',
            group: '1',
            width: 5,
        },
        filesCounter: {
            support: 'Кол-во файлов',
            type: 'input',
            group: '1',
            width: 3,
            concat: {
                text: [' файл', ' файла', ' файлов'],
                position: 'end',
                exp: /[^\d]/gi,
            },
            max: 2,
        },
        countries: {
            support: 'Гражданство',
            prop: 'countries',
            type: 'select',
            selectList: 'countryTypes',
            selectProps: {
                name: 'name',
            },
            multi: true,
            group: '1',
            width: 3.5,
        },
    },
} as const;

export default joinDocMain;
