import React, { MouseEvent } from 'react';

import Button from '@components/button/Button.tsx';
import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';
import handlerPopup from '@functions/handlerPopup.ts';
import { ListenerT } from '@global/types.ts';

import outClickHandler from './methods/outClickHandler.ts';

import CompanyListI from './types.ts';

import renderCompany from './renders/renderCompany.tsx';

class CompanyList
    extends React.Component<CompanyListI['props'], CompanyListI['state']>
    implements CompanyListI
{
    parent: CompanyListI['parent'];

    constructor(props: CompanyListI['props']) {
        super(props);
        this.state = {};

        this.outClickHandler = this.outClickHandler.bind(this);

        this.parent = React.createRef();
    }

    renderCompany = renderCompany;
    outClickHandler = outClickHandler;

    componentDidMount(): void {
        setTimeout(() => {
            (document.addEventListener as ListenerT<MouseEvent>)('click', this.outClickHandler);
        }, 10);
    }

    componentWillUnmount(): void {
        (document.removeEventListener as ListenerT<MouseEvent>)('click', this.outClickHandler);
    }

    render() {
        const { user, isAdmin, currentId } = this.props;
        const currentCorporations = user.corporationsInfo.filter((item) => item._id !== currentId);
        const hasAdmin = user.corporationsInfo.find((item) => item.isAdmin);

        return (
            <div ref={this.parent} className={`v2sideBarCompanyList ${isAdmin ? '_admin' : ''}`}>
                {currentCorporations.length > 0 && (
                    <div className="v2sideBarCompanyList__scroll _NOSCROLL">
                        <div className="v2sideBarCompanyList__scrollInner">
                            <ListAbsoluteMain
                                className="v2sideBarCompanyList__companies _ITEMS"
                                items={currentCorporations}
                                renderItem={this.renderCompany.bind(this)}
                                classNameItem="v2sideBarCompanyList__company"
                                prop="_id"
                                paramsParent={{ width: true }}
                                styles={['height']}
                                itemParams={['offsetTop']}
                            />
                        </div>
                    </div>
                )}

                {!hasAdmin && (
                    <div className="v2sideBarCompanyList__info _COL">
                        <div className="v2sideBarCompanyList__infoTitle">Это все компании</div>
                        <div className="v2sideBarCompanyList__infoDescription">
                            Но вы можете добавить ещё:
                        </div>
                        <div className="v2sideBarCompanyList__infoButton">
                            <Button
                                className="_main _mediumSize _shadow"
                                onClick={() => {
                                    handlerPopup('corporationCreatePopup', {
                                        isShow: true,
                                    });
                                }}
                            >
                                Добавить компанию
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default CompanyList;
