import React from 'react';

import Link from '@components/link/Link.tsx';
import getPage from '@functions/getPage.ts';
import { StoreT } from '@global/types.ts';

import I from '../types.ts';

import { NavItemT } from '../static/nav.ts';

const renderNavList: I['renderNavList'] = function ({ name }) {
    const { listsState, user, counters } = this.state;
    const item = this.nav[name] as NavItemT;
    const list = item.list?.filter((listItem) => {
        const page = getPage({ name: listItem });

        return !page.getCond || page.getCond({ user } as StoreT<'crm'>).condition;
    });

    return (
        <div
            className={`v2sideBar__navList _${name} ${listsState[name] ? '_show' : ''}`}
            key={name}
            onMouseEnter={() => {
                this.listStateHandler({ name, isShow: true });
            }}
            onMouseLeave={() => {
                this.listStateHandler({ name, isShow: false });
            }}
        >
            <div className="v2sideBar__navListInner">
                {list?.map((listItemName) => {
                    const page = getPage({ name: listItemName });
                    let content = page.contentOfLink;
                    const counter = counters[name]?.[listItemName] || 0;

                    if (
                        listItemName === 'content-legal' ||
                        listItemName === 'pays-tax' ||
                        listItemName === 'joins-mvd' ||
                        listItemName === 'manual-executorTypes'
                    ) {
                        content = content?.replace(/ /gi, '<br/>');
                    }

                    if (listItemName === 'chat') {
                        content = 'Все чаты';
                    }

                    if (counter > 0) {
                        content += ` <span>+${counter}</span>`;
                    }

                    const otherProps = {
                        dangerouslySetInnerHTML: { __html: content },
                    };

                    return (
                        <Link
                            className="v2sideBar__navListLink"
                            pageName={listItemName}
                            key={listItemName}
                            {...otherProps}
                        ></Link>
                    );
                })}
            </div>
        </div>
    );
};

export default renderNavList;
