import I from '../types.ts';

const socketHandler: I['socketHandler'] = function ({ detail: { name } }) {
    if (name === 'chat') {
        this.setCounterStack({ name: 'chats', itemName: 'chat' });
    }

    if (name === 'join') {
        this.setCounterStack({ name: 'joins', itemName: 'joins-invites' });
    }

    if (name === 'joinContracts') {
        this.setCounterStack({ name: 'joins', itemName: 'joins-contracts' });
    }

    if (name === 'pays') {
        this.setCounterStack({ name: 'pays', itemName: 'pays-main' });
    }
};

export default socketHandler;
