import I from '../types.ts';

const setCounterStack: I['setCounterStack'] = async function ({ name, itemName }) {
    if (!this.counters[itemName]) {
        this.counters[itemName] = {
            counter: 0,
        };
    }

    this.counters[itemName].counter += 1;

    if (!this.counters[itemName].timerId) {
        this.counters[itemName].timerId = setTimeout(async () => {
            if (this.counters[itemName].counter > 0) {
                await this.getCounter({ name, itemName });
            }

            this.counters[itemName].counter = 0;
            this.counters[itemName].timerId = undefined;
        }, 1_000);
    }
};

export default setCounterStack;
