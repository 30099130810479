import I from '../types.ts';

const outClickHandler: I['outClickHandler'] = function (e) {
    const target = e.target as HTMLElement;
    const { companyListHandler } = this.props;

    const parent = this.parent.current!;

    if (parent !== target && !parent.contains(target)) {
        companyListHandler(false);
    }
};

export default outClickHandler;
