import axios from 'axios';

import { dispatcher, store } from '../redux/redux';
import changePage from './changePage';
import connectSockets from './connectSockets';
import checkVerification from './crm/checkVerification';
import getHeaders from './getHeaders';
import { deleteCookie, setCookie } from './handlerCookies';
import handlerPopup from './handlerPopup';
import logout from './logout';

const remove = (isForce) =>
    new Promise((resolve, reject) =>
        logout().then(
            () => null,
            () => {
                const clear = () => {
                    localStorage.removeItem('user');

                    dispatcher({ type: 'user', data: null });
                    dispatcher({ type: 'isProccessLogin', data: false });
                    dispatcher({ type: 'isSocketConnect', data: false });
                    dispatcher({ type: 'idOfPage', data: null });
                    deleteCookie(process.env.REACT_APP_HASH);
                    deleteCookie('hashForPay');
                    deleteCookie('hashOrderInfo');
                    deleteCookie('idOfOrderPay');
                };

                if (isForce) {
                    clear();
                }

                changePage({
                    href: process.env.REACT_APP_SYSTEM === 'crm' ? 'auth' : 'forClients',
                }).then(
                    () => {
                        clear();

                        reject();
                    },
                    () => {
                        clear();

                        reject();
                    },
                );
            },
        ),
    );

export default function getUser(hash, isForce = false, isUpdate = false, id) {
    const levels = store.getState().levels;

    return new Promise((resolve, reject) => {
        if (hash || isForce) {
            if (hash) {
                setCookie(process.env.REACT_APP_HASH, hash);
                dispatcher({ type: process.env.REACT_APP_HASH, data: hash });
            }

            axios
                .get(`${process.env.REACT_APP_API}/login`, {
                    headers: getHeaders({ id }),
                })
                .then((res) => {
                    if (res.data) {
                        const { success, data } = res.data;

                        if (success === true) {
                            const { user } = data;

                            const currentCorporation =
                                user.corporations.find((item) => item.id === levels[0]) ||
                                user.corporations.find(
                                    (item) => item.id === store.getState().pages.corporation.id,
                                ) ||
                                user.corporations.find(
                                    (item) =>
                                        item.id === store.getState().user?.idOfCurrentCorporation,
                                ) ||
                                user.corporations[0];

                            if (currentCorporation) {
                                user.idOfCurrentCorporation = currentCorporation.id;
                            }

                            if (user.corporations.length === 0) {
                                handlerPopup('corporationCreatePopup', {
                                    isShow: true,
                                });
                            }

                            dispatcher({ type: 'user', data: user }).then(() => {
                                if (
                                    process.env.REACT_APP_ENV !== 'local' &&
                                    +process.env.REACT_APP_CRM_VERSION < user.systemVersion
                                ) {
                                    dispatcher({ type: 'newVersionShow', data: true });
                                }

                                document.dispatchEvent(
                                    new CustomEvent('changeUser', {
                                        detail: {},
                                    }),
                                );

                                checkVerification({});

                                deleteCookie('activateHash');
                                deleteCookie('activateId');
                                // deleteCookie('joinToken');

                                if (!isForce) {
                                    connectSockets(true);
                                }

                                if (!isUpdate) {
                                    document.dispatchEvent(new CustomEvent('setUser'));
                                }

                                // console.log(user);

                                resolve(user);
                            });
                        } else {
                            remove().then(resolve, reject);
                        }
                    }
                }, reject);
        } else if (localStorage.getItem('user')) {
            remove(true).then(resolve, reject);
        }
    });
}
