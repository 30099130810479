import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Icon from '@components/icon/Icon.tsx';
import changePage from '@functions/changePage.ts';
import getPage from '@functions/getPage.ts';

import I from '../types.ts';

import { NavItemT } from '../static/nav.ts';

const renderNavItem: I['renderNavItem'] = function ({ prop: name }) {
    const { listsState, counters } = this.state;
    const { storePages } = this.props;
    const item = this.nav[name] as NavItemT;
    const { list, pageName } = item;
    const page = getPage({ name: pageName });
    const isCurrent = list
        ? list.find((listItem) => storePages[listItem].isShow)
        : storePages[pageName].isShow;
    const counter = counters[name]
        ? Object.keys(counters[name])
              .map((itemName) => counters[name][itemName])
              .reduce((prev, cur) => prev + cur, 0)
        : 0;
    let content = page.contentOfLink;

    if (name === 'verification') {
        content = 'Верификация';
    }

    return (
        <div
            className={`v2sideBar__navItem _COL _CLICK _ITEM _${name} ${listsState[name] ? '_active' : ''} ${isCurrent ? '_current' : ''}`}
            onClick={() => {
                if (!list) {
                    changePage({ pageName });
                }
            }}
            onMouseEnter={() => {
                if (list) {
                    this.listStateHandler({ name, isShow: true });
                    this.setNavListPosition({ name });
                }
            }}
            onMouseLeave={() => {
                if (list) {
                    this.listStateHandler({ name, isShow: false });
                }
            }}
        >
            <div className="v2sideBar__navItemIcon">
                <div className="v2sideBar__navItemIconItem">
                    <Icon name={item.icon} />
                </div>
                <AnimateChange
                    className="v2sideBar__navItemCounter"
                    renderKey={counter || undefined}
                    styles={['width']}
                >
                    <>+{counter}</>
                </AnimateChange>
            </div>
            <div className="v2sideBar__navItemContent">{content}</div>
        </div>
    );
};

export default renderNavItem;
