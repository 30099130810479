import React from 'react';

import Avatar from '@components/avatar/Avatar.tsx';
import changeCorporation from '@functions/crm/changeCorporation.js';
import getFormatPrice from '@functions/getFormatPrice.ts';

import I from '../types.ts';

const renderCompany: I['renderCompany'] = function ({ item: company }) {
    const { saveUser, companyListHandler } = this.props;

    const resultId = company.isAdmin ? 'admin' : company._id;

    return (
        <div
            className="v2sideBarCompanyList__company _ITEM _ROW _CLICK"
            key={company._id}
            onClick={async () => {
                await changeCorporation({ id: resultId });

                companyListHandler(false);

                saveUser();
            }}
        >
            <div className="v2sideBarCompanyList__companyLogo">
                <Avatar
                    image={company.logo?.fullSrc}
                    firstSymb={company.fullName?.[0].toUpperCase()}
                    className="_grey"
                />
            </div>
            <div className="v2sideBarCompanyList__companyContent">
                <div className="v2sideBarCompanyList__companyName">
                    {company.shortName || company.fullName}
                </div>
                <div className="v2sideBarCompanyList__companyBalance">
                    {getFormatPrice(company.balance || 0)}₽
                </div>
            </div>
        </div>
    );
};

export default renderCompany;
