import axios from 'axios';
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import handlerPopup from '../../../functions/app/handlerPopup';
import getHeaders from '../../../functions/getHeaders';
import getUserInfo from '../../../functions/getUserInfo';
import setNotification from '../../../functions/setNotification';

import Animate from '../../Animate.jsx';
import Button from '../../Button.jsx';
import Checkbox from '../../Checkbox.jsx';
import Icon from '../../Icon.jsx';
import ImageLazy from '../../ImageLazy.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import Loader from '../../Loader.jsx';

class ChatExecutorDocPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderDoc = this.renderDoc.bind(this);
        this.save = this.save.bind(this);
    }

    renderDoc({ item, prop: _id }) {
        const { currentId } = this.state;
        const name = _id === 'other' ? item.name : `${item.name} — ${item.fileIndex + 1}&nbsp;файл`;

        return (
            <div className="chatExecutorDocPopup__item">
                <div
                    className={`chatExecutorDocPopup__doc _row _click ${item.filePath ? '_withPreview' : ''}`}
                    onClick={() => {
                        this.setState({ currentId: currentId === _id ? null : _id });
                    }}
                >
                    <div className="chatExecutorDocPopup__docCheckbox">
                        <Checkbox
                            className="_square _blue"
                            name={_id}
                            value={currentId === _id}
                            isDisabled={true}
                        />
                    </div>
                    {item.filePath && (
                        <div className="chatExecutorDocPopup__docPreview">
                            <ImageLazy className="_cover" src={item.filePath} />
                        </div>
                    )}
                    <div
                        className="chatExecutorDocPopup__docName"
                        dangerouslySetInnerHTML={{ __html: name }}
                    ></div>
                </div>
            </div>
        );
    }

    close() {
        handlerPopup({ name: 'chatExecutorDocPopup', isShow: false });
    }

    getExecutor() {
        const { chatExecutorDocPopup } = this.props;
        const { chatId } = chatExecutorDocPopup;

        axios
            .patch(
                `${process.env.REACT_APP_API}/chat`,
                {
                    action: 'executors-doc',
                    id: chatId,
                },
                {
                    headers: getHeaders(),
                },
            )
            .then((res) => {
                const { success, data } = res.data;

                if (success) {
                    const { executor } = data;

                    this.setState({ executor, isInit: true });
                }
            });
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    save() {
        const { currentId } = this.state;
        const { chatExecutorDocPopup } = this.props;
        const { chatId, file } = chatExecutorDocPopup;

        this.handlerLoading('save').then(() => {
            axios
                .patch(
                    `${process.env.REACT_APP_API}/chat`,
                    {
                        action: 'executors-doc',
                        id: chatId,
                        docId: currentId,
                        fileId: file._id,
                    },
                    {
                        headers: getHeaders(),
                    },
                )
                .then((res) => {
                    const { success } = res.data;

                    if (success) {
                        setNotification({ notification: 'success-change-info' });

                        this.close();
                    }

                    this.handlerLoading(null);
                });
        });
    }

    componentDidMount() {
        this.getExecutor();
    }

    render() {
        const { executor, isInit, currentId, loadingKey } = this.state;
        const { chatExecutorDocPopup } = this.props;
        const { file } = chatExecutorDocPopup;
        const docs = executor?.docs || [];

        return (
            <div className={`chatExecutorDocPopup _col ${isInit ? '_init' : ''}`}>
                <div className="chatExecutorDocPopup__inner">
                    <div className="chatExecutorDocPopup__close _click" onClick={this.close}>
                        <Icon name="close-circle" />
                    </div>
                    <Animate className="chatExecutorDocPopup__loader _loader" isShow={!isInit}>
                        <div className="chatExecutorDocPopup__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                    <div className="chatExecutorDocPopup__head _row">
                        <div className="chatExecutorDocPopup__headPreview">
                            <ImageLazy
                                src={file.path}
                                className="chatExecutorDocPopup__headPreviewImage"
                            />
                        </div>
                        <div className="chatExecutorDocPopup__headContent">
                            <div className="chatExecutorDocPopup__headTitle">
                                Прикрепить фото к&nbsp;исполнителю:
                            </div>
                            <p className="chatExecutorDocPopup__headDescription">
                                {getUserInfo({ type: 'name', user: executor })}
                            </p>
                        </div>
                    </div>
                    <div className="chatExecutorDocPopup__content">
                        <div className="chatExecutorDocPopup__title">
                            Выберите, куда прикрепить документ:
                        </div>
                        <div className="chatExecutorDocPopup__itemsBox">
                            <div className="chatExecutorDocPopup__itemsWrapper">
                                <ListAbsoluteMain
                                    className="chatExecutorDocPopup__items"
                                    items={docs}
                                    renderItem={this.renderDoc}
                                    classNameItem="chatExecutorDocPopup__item"
                                    prop="_id"
                                    paramsParent={{ width: true }}
                                    styles={['height']}
                                    minHeight={120}
                                />
                            </div>
                        </div>

                        <div className="chatExecutorDocPopup__button">
                            <Button
                                className="_main _mainNotBorder"
                                isDisabled={!currentId}
                                onClick={this.save}
                                showLoader={loadingKey === 'save'}
                            >
                                Прикрепить фото
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        chatExecutorDocPopup: state.chatExecutorDocPopup,
    };
}

export default connect(mapStateToProps)(ChatExecutorDocPopup);

ChatExecutorDocPopup.propTypes = {
    chatExecutorDocPopup: PropTypes.object,
};
