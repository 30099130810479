import getPage from '@functions/getPage.ts';
import { StoreT } from '@global/types.ts';

import I from '../types.ts';

import { NavItemT } from '../static/nav.ts';

const getCounters: I['getCounters'] = async function () {
    const { user } = this.state;
    const nav = this.getNav();

    const resultItems: { name: string; itemName: string }[] = [];

    nav.forEach((item) => {
        resultItems.push({ name: item.name, itemName: item.name });

        const navItem = this.nav[item.name] as NavItemT;

        if (navItem.list) {
            resultItems.push(
                ...navItem.list
                    .filter((listItem) => {
                        const listPage = getPage({ name: listItem });

                        return (
                            !listPage.getCond ||
                            listPage.getCond({ user } as StoreT<'crm'>).condition
                        );
                    })
                    .map((listItem) => ({ name: item.name, itemName: listItem })),
            );
        }
    });

    await Promise.all(
        resultItems.map(async ({ name, itemName }) => {
            await this.getCounter({ name, itemName });
        }),
    );
};

export default getCounters;
