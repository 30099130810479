import getPermissions from '@functions/crm/getPermissions.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import { CursorActionsItemT } from '@global/types.ts';

import InvitesI from '../types.ts';

const getMoreActions: InvitesI['getMoreActions'] = function ({ pay }) {
    const { user } = this.state;
    const { groupId, executorId } = this.props;
    const actions = [
        {
            key: 'logs',
            name: 'logs',
            onClick: async () => {
                handlerPopup('logsPopup', {
                    isShow: true,
                    modelName: 'pay',
                    modelId: pay._id,
                });
            },
        },
    ] as CursorActionsItemT[];

    if (
        !executorId &&
        getPermissions(user, {
            notAdminCorporation: true,
            key: 'pays',
            items: [{ key: 'acts', rules: ['create'] }],
        })
    ) {
        actions.unshift({
            key: 'repeat',
            name: 'repeatPay',
            text: 'Повторить акт',
            onClick: async () => {
                handlerPopup('payPopup', {
                    isShow: true,
                    pay,
                    ...(groupId !== 'main' ? { groupId } : {}),
                });
            },
        });
    }

    if (
        !pay.type &&
        !pay.wasStart &&
        !pay.isCorporationSign &&
        !pay.isExecutorSign &&
        ['wait', 'error', 'failed'].includes(pay.status) &&
        getPermissions(user, {
            key: 'pays',
            items: [{ key: 'acts', rules: ['update'] }],
        }) &&
        (user?.idOfCurrentCorporation !== 'admin' || pay.adminParse)
    ) {
        actions.unshift({
            key: 'edit',
            name: 'editPay',
            text: 'Редактировать акт',
            onClick: async () => {
                handlerPopup('payPopup', {
                    isShow: true,
                    pay,
                    isEdit: true,
                    ...(groupId !== 'main' ? { groupId } : {}),
                });
            },
        });
    }

    if (
        pay.status === 'error' &&
        getPermissions(user, {
            notAdminCorporation: pay.type !== 'tax',
            key: 'pays',
            items: [
                {
                    key: 'acts',
                    rules: [],
                    actions: ['checkError'],
                },
            ],
        })
    ) {
        actions.unshift({
            key: 'checkError',
            name: 'checkPayError',
            text: 'Проверить ошибку',
            onClick: this.checkError.bind(this, { pay }),
        });
    }

    if (
        pay.status === 'failed' &&
        getPermissions(user, {
            adminCorporation: true,
            key: 'pays',
            items: [
                {
                    key: 'acts',
                    rules: [],
                    actions: ['clearError'],
                },
            ],
        })
    ) {
        actions.unshift({
            key: 'repeat',
            name: 'clearFailed',
            text: 'Сбросить ошибку',
            onClick: this.clearError.bind(this, { pay }),
        });
    }

    if (
        getPermissions(user, { forHolder: true }) &&
        pay.status === 'completed' &&
        pay.isNonResident
    ) {
        actions.unshift({
            key: 'download',
            name: 'createNonResidentAct',
            text: 'Договор-счёт-акт',
            onClick: this.downloadAct.bind(this, { pay }),
        });
    }

    if (
        !executorId &&
        getPermissions(user, {
            adminCorporation: true,
            key: 'pays',
            items: [{ key: 'acts', rules: [], actions: ['refreshDocs'] }],
        }) &&
        !pay.type &&
        pay.corporationSign.isComplete
    ) {
        if (pay.actFileName) {
            actions.unshift({
                key: 'repeat',
                name: 'refreshAct',
                text: 'Обновить файл акта',
                onClick: this.refreshDoc.bind(this, { pay, type: 'act' }),
            });
        }

        if (pay.receiptFileName) {
            actions.unshift({
                key: 'repeat',
                name: 'refreshReceipt',
                text: 'Обновить файл чека',
                onClick: this.refreshDoc.bind(this, { pay, type: 'receipt' }),
            });
        }
    }

    if (
        getPermissions(user, {
            notAdminCorporation: true,
            key: 'pays',
            items: [{ key: 'acts', rules: ['sign'], actions: [] }],
        }) &&
        pay.status === 'process' &&
        pay.corporationSign.isComplete &&
        pay.executorSign.isNotSign !== true
    ) {
        actions.unshift({
            key: 'remove',
            name: 'stop',
            text: 'Остановить акт',
            onClick: this.stopAct.bind(this, { pay }),
        });
    }

    return actions;
};

export default getMoreActions;
