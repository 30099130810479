import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import handlerLoading from '@functions/handlerLoading.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const save: I['save'] = async function () {
    const model = this.state.model!;
    const change = this.checkChange(true);

    const fields: Record<any, unknown> = {
        ...change.model,
    };

    if (change.model!.tags || change.deleteItems?.find((item) => item.includes('tags.'))) {
        fields.tags = model.tags.map((tag: { _id: string }) => ({ id: tag._id }));
    }

    await handlerLoading.call(this, 'save');

    const response = await axios.patch<
        {},
        { data: ReqResponseT<{ message: string; error?: { name: string; text: string } }> }
    >(
        `${process.env.REACT_APP_API}/chat`,
        { id: model._id, action: 'update', fields },
        { headers: getHeaders() },
    );

    const { success, data } = response.data;

    if (success) {
        this.close();
    } else {
        const { error } = data;

        if (error) {
            this.setState({ error });
        }
    }

    await handlerLoading.call(this, undefined);
};

export default save;
