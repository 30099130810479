import setAsyncState from '@functions/setAsyncState.ts';

import I from '../types.ts';

const saveUser: I['saveUser'] = async function (this: I) {
    await setAsyncState.call(this, {
        user: this.props.user,
        notAdminCorporation: this.getNotAdminCorporation(),
    });

    Object.keys(this.counters).forEach((key) => {
        const counter = this.counters[key];

        if (counter.timerId) {
            clearTimeout(counter.timerId);

            counter.counter = 0;
            counter.timerId = undefined;
        }
    });

    this.getCounters();
    this.setContentHeight();
};

export default saveUser;
