import InvitesI from '../types.ts';

const handlerSocket: InvitesI['handlerSocket'] = async function ({ detail }) {
    const { user } = this.state;
    const { name, data } = detail;

    if (name === 'join') {
        const { fields } = data;
        const corporationId = fields?.corporationId;

        if (
            !corporationId ||
            corporationId === user?.idOfCurrentCorporation ||
            user?.idOfCurrentCorporation === 'admin'
        ) {
            if (fields?.isCreate) {
                await this.tableUpdateItems!();
            } else if (data.fields) {
                await this.tableUpdateItem!({ id: data.id, fields: data.fields });
            }
        }
    }
};

export default handlerSocket;
