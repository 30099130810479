import axios from 'axios';

import changePage from '@functions/changePage.ts';
import getFormatPhone from '@functions/getFormatPhone.ts';
import getHeaders from '@functions/getHeaders.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const deleteModel: I['deleteModel'] = async function (force) {
    const { model } = this.state;

    let query = `id=${model?._id}`;

    if (force === true) {
        query += `&force=${force}`;
    }

    try {
        const response = await axios.delete<ReqResponseT<{ message: string }>>(
            `${process.env.REACT_APP_API}/executor?${query}`,
            { headers: getHeaders() },
        );
        const { success, data } = response.data;

        if (success) {
            if (!force) {
                handlerPopup('alertPopup', {
                    isShow: true,
                    type: 'deleteExecutor',
                    info: model!.fullName || getFormatPhone({ phone: model!.phone }),
                    callback: deleteModel.bind(this, true),
                });

                return;
            }

            changePage<'crm'>({ pageName: 'manual-executors-main' });

            return;
        }

        const { message } = data;

        if (message === 'Model denied delete') {
            handlerPopup('alertPopup', {
                isShow: true,
                type: 'deleteExecutorDenied',
                info: model?.name,
                denied: true,
            });

            return;
        }
    } catch (error) {}

    return Promise.reject();
};

export default deleteModel;
