import I from '../types.ts';

const setContentHeight: I['setContentHeight'] = function () {
    const bar = this.parent.current!;
    const head = bar.querySelector('.v2sideBar__head') as HTMLElement;
    const content = bar.querySelector('.v2sideBar__content') as HTMLElement;
    const foot = bar.querySelector('.v2sideBar__foot') as HTMLElement;

    const resultHeight = bar.offsetHeight - head.offsetHeight - foot.offsetHeight;

    content.style.height = `${resultHeight}px`;
};

export default setContentHeight;
