import React from 'react';

import Tag from '@components/crm/tag/Tag';
import clearPhone from '@functions/clearPhone';
import TagT from '@global/models/Tag';

const chatEdit = {
    fieldsOrder: ['name', 'phone', 'userId', 'responsibleId', 'line', 'tags'],
    fields: {
        name: {
            support: 'Имя',
            type: 'input',
            disabled: (model?: { userId?: string }) => !!model?.userId,
        },
        phone: {
            support: 'Телефон',
            type: 'input',
            reg: 'phone',
            disabled: (model?: { userId?: string }) => !!model?.userId,
        },
        userId: {
            support: 'Исполнитель',
            prop: 'userId',
            selectTextKey: 'executorName',
            type: 'select',
            selectList: 'executors',
            selectProps: { fullName: 'executorName' },
            setSelectChange(model?: { phone: string; fullName: string }) {
                return {
                    name: model?.fullName,
                    phone: model ? clearPhone(model?.phone) : null,
                };
            },
        },
        responsibleId: {
            support: 'Ответственный',
            prop: 'responsibleId',
            selectTextKey: 'responsibleName',
            type: 'select',
            selectList: 'users',
            selectProps: { fullName: 'responsibleName' },
        },
        line: {
            type: 'line',
        },
        tags: {
            support: 'Теги',
            type: 'list',
            prop: 'tags',
            list: {
                name: 'tags',
                render(tag: TagT) {
                    return <Tag color={tag.color}>{tag.name}</Tag>;
                },
                multi: true,
                button: 'Выбрать теги',
            },
        },
    },
} as const;

export default chatEdit;
