import React from 'react';

import Animate from '@components/animate/Animate.tsx';

import Company from '../components/Company.tsx';
import Settings from '../components/Settings.tsx';
import CompanyList from '../components/companyList/CompanyList.tsx';

import I from '../types.ts';

const renderHead: I['renderHead'] = function () {
    const { user, showCompanyList } = this.state;
    const corporation = this.getCorporation();

    return (
        <div className={`v2sideBar__head ${showCompanyList ? '_showList' : ''}`}>
            <div className="v2sideBar__headInfo">
                <Company
                    corporation={corporation}
                    withList={this.checkList()}
                    companyListHandler={this.companyListHandler.bind(this)}
                />
                <Settings
                    user={user}
                    corporation={corporation}
                    notAdminCorporation={this.getNotAdminCorporation()}
                    saveUser={this.saveUser.bind(this)}
                    companyListHandler={this.companyListHandler.bind(this)}
                />
                {user && (
                    <Animate className="v2sideBar__headList" isShow={!!showCompanyList}>
                        <CompanyList
                            user={user}
                            currentId={corporation?._id}
                            isAdmin={corporation?.isAdmin}
                            saveUser={this.saveUser.bind(this)}
                            companyListHandler={this.companyListHandler.bind(this)}
                        />
                    </Animate>
                )}
            </div>
        </div>
    );
};

export default renderHead;
