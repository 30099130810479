import getPage from '@functions/getPage.ts';
import { StoreT } from '@global/types.ts';
import getListItems from '@requests/getListItems.ts';
import getNotifications from '@requests/getNotifications.js';

import I from '../types.ts';

const getCounter: I['getCounter'] = async function ({ name, itemName }) {
    const { user } = this.state;
    const corporation = this.getCorporation();

    const requestCounter = async () => {
        if (name === 'verification') {
            return { counter: corporation?.verificationStatus !== 'active' ? 1 : 0 };
        }

        if (itemName === 'chat') {
            return await getListItems({
                url: 'chat',
                query: [
                    { key: 'getCounter', value: 'true' },
                    { key: 'notRead', value: 'true' },
                ],
            });
        }

        if (itemName === 'joins-invites') {
            return await getNotifications({
                params: [
                    { key: 'getCounter', value: 'true' },
                    { key: 'name', value: 'checkJoinExecutor' },
                ],
            } as any);
        }

        if (itemName === 'joins-contracts' && user?.idOfCurrentCorporation !== 'admin') {
            return await getListItems({
                url: 'join-contract',
                query: [
                    { key: 'inList', value: 'true' },
                    { key: 'getCounter', value: 'true' },
                    { key: 'signStatuses', value: 'notCorporation' },
                ],
            });
        }

        if (itemName === 'joins-mvd' && user?.idOfCurrentCorporation !== 'admin') {
            return await getListItems({
                url: 'executor-mvd',
                query: [
                    { key: 'inList', value: 'true' },
                    { key: 'getCounter', value: 'true' },
                ],
            });
        }

        if (itemName === 'pays-main') {
            return await getListItems({
                url: 'pays',
                query: [
                    { key: 'inList', value: 'true' },
                    { key: 'getCounter', value: 'true' },
                    { key: 'notCompleted', value: 'true' },
                    { key: 'groupId', value: 'main' },
                ],
            });
        }
    };

    const page = getPage({ name: itemName });

    if (!page || !page.getCond || page.getCond({ user } as StoreT<'crm'>).condition) {
        try {
            const info = await requestCounter();
            const counter = info?.counter || 0;

            await this.setCounter({ name, itemName, counter });
        } catch (error) {}
    }
};

export default getCounter;
