import React from 'react';

import Icon from '@components/icon/Icon';
import Link from '@components/link/Link';
import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain';
import changeCorporation from '@functions/crm/changeCorporation';
import getPermissions from '@functions/crm/getPermissions';
import { StoreT } from '@global/types';

import SideBarI from '../types';

type ParamsT = {
    user?: StoreT<'crm'>['user'];
    corporation: ReturnType<SideBarI['getCorporation']>;
    notAdminCorporation: string | undefined;
    saveUser: SideBarI['saveUser'];
    companyListHandler: SideBarI['companyListHandler'];
};

const renderButton = function (
    props: ParamsT,
    { prop, item }: { prop: string; item: { _id: string; corporationName?: string } },
) {
    if (prop === 'settings') {
        return (
            <Link className="v2sideBar__headSettingsButton _ITEM _ROW" pageName="settings">
                <i className="v2sideBar__headSettingsButtonIcon _settings">
                    <Icon name="sideBar-settings" />
                </i>
                Настройки
            </Link>
        );
    }

    if (prop === 'infoPublic') {
        return (
            <Link
                className="v2sideBar__headSettingsButton _ITEM _ROW"
                pageName="content-infoPublic"
            >
                <i className="v2sideBar__headSettingsButtonIcon _settings">
                    <Icon name="sideBar-content" />
                </i>
                База знаний
            </Link>
        );
    }

    const { user, saveUser, companyListHandler } = props;

    if (prop === 'admin') {
        return (
            <div
                className="v2sideBar__headSettingsButton _ITEM _ROW _CLICK _admin"
                onClick={async () => {
                    await changeCorporation({ user, id: 'admin' });

                    companyListHandler(false);

                    saveUser();
                }}
            >
                <i className="v2sideBar__headSettingsButtonIcon _admin">
                    <Icon name="admin" />
                </i>
                Админ панель
                <i className="v2sideBar__headSettingsButtonIcon _nextArrow">
                    <Icon name="arrow-next-2" />
                </i>
            </div>
        );
    }

    return (
        <div
            className="v2sideBar__headSettingsButton _ROW _ITEM _CLICK"
            onClick={async () => {
                await changeCorporation({ user, id: item?._id });

                companyListHandler(false);

                saveUser();
            }}
        >
            <i className="v2sideBar__headSettingsButtonIcon _prevArrow">
                <Icon name="arrow-prev-2" />
            </i>
            Вернуться в {item?.corporationName}
        </div>
    );
};

const getItems = function ({
    user,
    corporation,
    notAdminCorporation,
}: ParamsT): { _id?: string; name: string; corporationName?: string }[] {
    const items: { _id?: string; name: string; corporationName?: string }[] = [];

    if (getPermissions(user, { key: 'settings' })) {
        items.push({ name: 'settings' });
    }

    if (!corporation?.isAdmin) {
        if (user?.corporations.find((item) => item.id === 'admin')) {
            items.push({ name: 'admin' });
        } else {
            items.push({ name: 'infoPublic' });
        }
    } else if (
        user?.corporations.filter((item) => item.id !== 'admin').length &&
        notAdminCorporation
    ) {
        const thisCorporation = user.corporationsInfo.find(
            (item) => item._id === notAdminCorporation,
        );

        items.push({
            _id: notAdminCorporation,
            name: `corporations-${notAdminCorporation}`,
            corporationName: thisCorporation?.shortName || thisCorporation?.fullName,
        });
    }

    return items;
};

export default function Settings({ ...props }: ParamsT): React.ReactNode {
    const items = getItems({ ...props });

    return (
        <div className="v2sideBar__headSettings">
            <ListAbsoluteMain
                className="v2sideBar__headSettingsInner _ITEMS"
                items={items}
                renderItem={renderButton.bind(null, props)}
                classNameItem="v2sideBar__headSettingsButton"
                prop="name"
                styles={['width']}
                itemParams={['offsetLeft', 'offsetBottom']}
            />
        </div>
    );
}
