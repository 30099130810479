import React from 'react';
import { connect } from 'react-redux';

import Widget from '@components/crm/manual/widget/Widget.tsx';
import WidgetWrapper from '@components/crm/manual/widgetWrapper/WidgetWrapper.tsx';
import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';
import Pages from '@components/pages/Pages.tsx';
import { StoreT } from '@global/types.ts';

import getActions from './methods/getActions.ts';
import getId from './methods/getId.ts';
import getQuery from './methods/getQuery.ts';

import JoinScriptInnerI from './types.ts';

import deleteModel from './actions/deleteModel';
import pages from './static/pages.tsx';

class JoinScriptInner
    extends WidgetWrapper<JoinScriptInnerI['props'], JoinScriptInnerI['state']>
    implements JoinScriptInnerI
{
    savedId: JoinScriptInnerI['savedId'];

    constructor(props: JoinScriptInnerI['props']) {
        super(props);
        this.state = {};
    }

    url = 'executor';
    pages = pages;

    deleteModel = deleteModel;

    getId = getId;
    getActions = getActions;
    getQuery = getQuery;

    renderWidget() {
        const { model } = this.state;

        return (
            <div className="body__innerPage _ITEM">
                <Widget
                    name="executors"
                    title="Справочник"
                    descriptionInner={model?.new ? 'Исполнитель новый' : 'Исполнитель'}
                    actions={this.getActions!()}
                    isFull={true}
                >
                    <LoaderBlock
                        className="v2widget__contentLoader"
                        isShow={!model}
                        loaderClassName="_main"
                    />
                    <div className={`v2widget__contentWait ${model ? '_show' : ''}`}>
                        {model && (
                            <Pages
                                className="body__innerPages _ITEMS"
                                classNamePage="body__innerPage _ITEM"
                                filter={(page) => page.parentName === 'manual-executors-inner'}
                                pages={this.pages}
                                context={this}
                            />
                        )}
                    </div>
                </Widget>
            </div>
        );
    }

    componentDidMount(): void {
        this.savedId = this.getId();

        this.getModel();

        this.setState({ user: this.props.user });
    }

    componentDidUpdate() {
        const id = this.getId();

        if (id && this.savedId !== id) {
            this.savedId = id;

            this.getModel();
        }
    }

    render() {
        const { model } = this.state;

        return (
            <>
                <Widget
                    name="executors"
                    title="Справочник"
                    descriptionInner={model?.new ? 'Исполнитель новый' : 'Исполнитель'}
                    actions={this.getActions!()}
                    isFull={true}
                >
                    <LoaderBlock
                        className="v2widget__contentLoader"
                        isShow={!model}
                        loaderClassName="_main"
                    />
                    <div className={`v2widget__contentWait ${model ? '_show' : ''}`}>
                        {model && (
                            <Pages
                                className="body__innerPages _ITEMS"
                                classNamePage="body__innerPage _ITEM"
                                filter={(page) => page.parentName === 'manual-executors-inner'}
                                pages={this.pages}
                                context={this}
                                key={model._id}
                            />
                        )}
                    </div>
                </Widget>
            </>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        levels: state.levels,
        user: state.user,
    };
}

export default connect(mapStateToProps)(JoinScriptInner);
