import React from 'react';

import BorderDashed from '@components/BorderDashed.jsx';
import WidgetField from '@components/crm/widgetField/WidgetField.tsx';
import Icon from '@components/icon/Icon.tsx';
import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';

import I from '../types.ts';

const renderContent: I['renderContent'] = function () {
    const { model, loadingKey } = this.state;

    return (
        <>
            <div className="v2popup__content _CENTER _NOSCROLL">
                <div className="v2popup__contentInner">
                    <div className="v2popup__close _top _CLICK" onClick={this.close.bind(this)}>
                        <Icon name="popup-close" />
                    </div>
                    <div className="v2popup__title">
                        Оформление пачки платежей
                        <br />
                        загрузкой Excel-файла
                    </div>
                    <div className="v2popup__description">
                        Скачайте наш шаблон, заполните в нём
                        <br />
                        все данные и загрузите заполненный файл.
                    </div>
                    <div className="v2popup__upload">
                        <div className="v2popupUpload">
                            <div className="v2popupUpload__fields _ROW">
                                <div className="v2popupUpload__field">
                                    <WidgetField
                                        model={model}
                                        className="_bigSize"
                                        name="projectId"
                                        prop="projectId"
                                        type="select"
                                        inputSupport="Проект"
                                        onChange={(data) => {
                                            this.change(data);
                                        }}
                                        value={model?.projectId}
                                        iserror={false}
                                        cardName="payGroupUpload"
                                        disabled={loadingKey === 'save'}
                                        selectList="payProjects"
                                        selectProps={{ name: 'projectName' }}
                                        selectText={
                                            this.getValue({ model, key: 'projectName' })?.value ||
                                            ''
                                        }
                                    />
                                </div>
                                <div className="v2popupUpload__field">
                                    <a
                                        className="v2popupUpload__template _ROW"
                                        href={require(`../../../../img/crm/pays.xlsx`)}
                                        download="Шаблон Реестр актов.xlsx"
                                    >
                                        Скачать шаблон
                                        <i className="v2popupUpload__templateIcon">
                                            <Icon name="file-type-doc" />
                                        </i>
                                    </a>
                                </div>
                            </div>
                            <label className="v2popupUpload__file _COL">
                                <input
                                    type="file"
                                    className="v2popupUpload__fileInput"
                                    accept=".xlsx,.xls"
                                    onChange={this.uploadFile.bind(this)}
                                    disabled={loadingKey === 'save'}
                                />
                                <div className="v2popupUpload__fileBack">
                                    <BorderDashed className="_upload" />
                                </div>
                                <div className="v2popupUpload__fileIcon">
                                    <LoaderBlock
                                        className="v2popupUpload__fileIconLoader _FULL"
                                        isShow={loadingKey === 'save'}
                                        loaderClassName="_main"
                                    />
                                    <div
                                        className={`v2popupUpload__fileIconItem ${loadingKey === 'save' ? '_loading' : ''}`}
                                    >
                                        <Icon name="file-upload" />
                                    </div>
                                </div>
                                <div className="v2popupUpload__fileTitle">
                                    Загрузите сюда
                                    <br />
                                    заполненный файл
                                </div>
                                <div className="v2popupUpload__fileDescription">
                                    В формате .xlsx
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default renderContent;
