import I from '../types.ts';

const getNotAdminCorporation: I['getNotAdminCorporation'] = function () {
    const { user } = this.state;

    if (!user) {
        return;
    }

    const corporationId =
        localStorage.getItem('saveNotAdminCorporationId') ||
        user.corporations.filter((item) => item.id !== 'admin')[0]?.id;

    return corporationId;
};

export default getNotAdminCorporation;
