import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import Avatar from '@components/avatar/Avatar';
import getUserName from '@functions/getUserName';
import { setCursorSupport } from '@functions/supportHandler';

import setPermissions from '../../functions/crm/setPermissions';
import getEnvParams from '../../functions/getEnvParams';
import getFormatedDate from '../../functions/getFormatedDate';
import getUserInfo from '../../functions/getUserInfo';
import handlerWindow from '../../functions/handlerWindow';

import Animate from '../Animate.jsx';
import AnimateChange from '../AnimateChange.jsx';
import Icon from '../Icon.jsx';
import Link from '../Link.jsx';
import CounterNotRead from './CounterNotRead.jsx';

class Preview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.setCounter = this.setCounter.bind(this);

        setPermissions.call(this, { userProp: 'storeUser' });
    }

    getType() {
        const { isClose, type, typePreview } = this.props;
        let resultType;
        const userType =
            typePreview === 'executor' ? 'Исполнитель' : typePreview === 'client' ? 'Клиент' : '';

        if (process.env.REACT_APP_SYSTEM === 'site' && isClose) {
            resultType = 'Обращение закрыто';
        }

        if (!type) {
            resultType = 'Обращение';
        }

        if (type === 'order') {
            resultType = 'Заказ';
        }

        if (type === 'orderInfo') {
            resultType = 'Клиент на точке';
        }

        if (type === 'telegram') {
            return 'Телеграм';
        }

        if (type === 'support') {
            return 'Поддержка';
        }

        return `${userType} | ${resultType}`;
    }

    checkCurrent() {
        const { typeChat, levels, _id, isCurrent } = this.props;

        return typeChat === 'fix' ? isCurrent : levels[getEnvParams().keyLevelsChatInner] === _id;
    }

    setCounter(counterNotRead) {
        const { _id, updatePreview } = this.props;

        updatePreview({ id: _id, props: { counterNotRead } });
    }

    renderResponsible() {
        const { _id, responsible, infoResponsible } = this.props;

        if (!responsible) {
            return null;
        }

        let content = getUserInfo({ type: 'name', user: infoResponsible });

        if (infoResponsible.nameOfRole) {
            content = `${infoResponsible.nameOfRole}<br/>${content}`;
        }

        const supportProps = setCursorSupport(
            {
                _id: `chat-preview-${_id}`,
                content,
                dir: 'top',
            },
            { targetClassName: '.chatPreview__responsible', className: '' },
        );

        return (
            <Animate className="chatPreview__responsible" isShow={!!responsible} {...supportProps}>
                <AnimateChange
                    className="chatPreview__responsibleInner"
                    prop={responsible}
                    isNotParams={true}
                    type="_translateMedium"
                >
                    <div className="chatPreview__responsibleItem _col">
                        <Avatar
                            image={infoResponsible.logo?.fullSrc}
                            firstSymb={infoResponsible.firstName?.[0]}
                            secondSymb={infoResponsible.secondName?.[0]}
                        />
                    </div>
                </AnimateChange>
            </Animate>
        );
    }

    getActionsItems() {
        const { storeUser, responsible } = this.props;

        const items = [];

        if (
            this.getPermissions({
                key: 'chat',
                items: [{ key: 'chats', rules: ['update'] }],
            })
        ) {
            if (storeUser?._id !== responsible) {
                items.unshift('setResponsibleSelf');
            }
        }

        if (
            this.getPermissions({
                key: 'chat',
                items: [{ key: 'chats', rules: ['delete'] }],
            })
        ) {
            items.push(...['choiceChat', 'deleteChat']);
        }

        return items;
    }

    handlerChoice({ target }) {
        const { _id, getParent, responsible, windows } = this.props;
        const parent = getParent();

        const items = this.getActionsItems();

        const uniqKey = `chatPreviewActions-${_id}`;

        if (!windows[uniqKey] && items.length) {
            const resultTarget = target.closest('.chatPreview__more');

            handlerWindow({
                parent,
                parentResize: parent,
                parentScroll: parent.querySelector('.chatList__boxScroll'),
                target: resultTarget,
                action: 'show',
                name: 'chatPreviewActions',
                className: '_actions',
                uniqKey,
                chatId: _id,
                items,
                isHideFromScroll: true,
                savedTarget: resultTarget,
                responsible,
                centers: {
                    left: 0.5,
                    top: 0,
                },
            });
        } else {
            handlerWindow({
                action: 'hide',
                name: 'chatPreviewActions',
            });
        }
    }

    getStatus() {
        const { storeUser, type, entity } = this.props;
        let statusKey = process.env.REACT_APP_SYSTEM === 'app' ? 'entityStatus' : 'userStatus';

        if (type === 'support' && storeUser && storeUser._id === entity.id) {
            statusKey = 'entityStatus';
        }

        const status = this.props[statusKey];

        const statuses = {
            1: 'complete',
            2: 'proccess',
            3: 'important',
        };

        return statuses[status];
    }

    render() {
        const {
            typeChat,
            isClose,
            _id,
            user,
            counterNotRead,
            isChoice,
            isChoiceMode,
            setChoice,
            levels,
            type,
            entity,
            storeUser,
        } = this.props;
        const lastMessage = this.props.lastMessage || {};
        let { content, createdDate } = lastMessage;
        const { files, createrId } = lastMessage;
        let resultType = type;

        if (type === 'support' && storeUser && storeUser._id !== entity.id) {
            resultType = 'adminSupport';
        }

        if (!createdDate) {
            createdDate = this.props.date;
        }

        const dateString = getFormatedDate({ date: new Date(createdDate), type: 'time' });

        // const href = process.env.REACT_APP_SYSTEM === 'crm' ? 'chat-inner' : 'cabinet-chat-inner';

        if (!content && files) {
            content = `Файлы: ${files.length}`;
        }

        if (Object.keys(lastMessage).length === 0) {
            content = 'Новый чат';

            if (resultType === 'support') {
                content = 'Начать чат';
            }
        }

        if (storeUser?._id === createrId) {
            content = `Вы: ${content}`;
        }

        const userName = user?.secondName ? getUserName({ user, type: 'short' }) : user?.name;
        const status = this.getStatus();

        return (
            <Link
                href={`${levels[0]}/chat/${levels[2]}/${_id}`}
                className={`chatPreview ${type === 'support' ? '_support' : ''} ${this.checkCurrent() ? '_current' : ''} ${
                    counterNotRead ? '_withCounter' : ''
                } ${isClose ? '_isClose' : ''} _${status} ${isChoiceMode ? '_choiceMode' : ''}`}
                isDisabled={typeChat === 'fix'}
                onClick={
                    isChoiceMode
                        ? () => {
                              setChoice({ id: _id });
                          }
                        : null
                }
            >
                {process.env.REACT_APP_SYSTEM === 'crm' && (
                    <>
                        <div className="chatPreview__preview">
                            <div className="chatPreview__previewInner">
                                <Avatar
                                    className="_grey"
                                    image={user?.src}
                                    firstSymb={user?.firstName?.[0]}
                                    secondSymb={user?.secondName?.[0]}
                                />
                            </div>

                            {this.renderResponsible()}
                        </div>
                        <div className="chatPreview__counter">
                            <CounterNotRead
                                startCounter={counterNotRead}
                                id={_id}
                                callback={this.setCounter}
                            />
                        </div>
                        {this.getActionsItems().length > 0 && type !== 'support' && (
                            <div
                                className="chatPreview__more _click"
                                onClick={(e) => {
                                    e.stopPropagation();

                                    this.handlerChoice({ target: e.target });
                                }}
                            >
                                <div className="chatPreview__moreIcon">
                                    <Icon name="more-vertical" />
                                </div>
                            </div>
                        )}

                        <div
                            className={`chatPreview__choice _col _click ${
                                isChoice ? '_active' : ''
                            }`}
                        >
                            <div className="chatPreview__choicePoint"></div>
                        </div>
                    </>
                )}
                <div className="chatPreview__content _col">
                    <div className="chatPreview__title">{userName}</div>
                    <div className="chatPreview__message">
                        <div className="chatPreview__messageInner">{content}</div>
                    </div>
                    <div className="chatPreview__type">{this.getType()}</div>

                    {createdDate && (
                        <div className="chatPreview__time">
                            <div className="chatPreview__timeInner">{dateString}</div>
                        </div>
                    )}
                </div>
            </Link>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        windows: state.windows,
    };
}

export default connect(mapStateToProps)(Preview);

Preview.propTypes = {
    lastMessage: PropTypes.object,
    type: PropTypes.string,
    number: PropTypes.string,
    _id: PropTypes.string,
    levels: PropTypes.array,
    typeChat: PropTypes.string,
    isCurrent: PropTypes.bool,
    isChangeSort: PropTypes.bool,
    isClose: PropTypes.bool,
    date: PropTypes.string,
    company: PropTypes.object,
    user: PropTypes.object,
    responsible: PropTypes.string,
    infoResponsible: PropTypes.object,
    updatePreview: PropTypes.func,
    counterNotRead: PropTypes.number,
    storeUser: PropTypes.object,
    typePreview: PropTypes.string,
    getParent: PropTypes.func,
    setChoice: PropTypes.func,
    isChoice: PropTypes.bool,
    isChoiceMode: PropTypes.bool,
    windows: PropTypes.object,
    entity: PropTypes.object,
    corporation: PropTypes.object,
};
