import React from 'react';
import { connect } from 'react-redux';

import { CustomListenerT, StoreT } from '@global/types.ts';

import checkList from './methods/checkList.ts';
import companyListHandler from './methods/companyListHandler.ts';
import getCorporation from './methods/getCorporation.ts';
import getCounter from './methods/getCounter.ts';
import getCounters from './methods/getCounters.ts';
import getNav from './methods/getNav.ts';
import getNotAdminCorporation from './methods/getNotAdminCorporation.ts';
import listStateHandler from './methods/listStateHandler.ts';
import saveUser from './methods/saveUser.ts';
import setContentHeight from './methods/setContentHeight.ts';
import setCounter from './methods/setCounter.ts';
import setCounterStack from './methods/setCounterStack.ts';
import setNavListPosition from './methods/setNavListPosition.ts';
import socketHandler from './methods/socketHandler.ts';

import SideBarI from './types.ts';

import renderContent from './renders/renderContent.tsx';
import renderFoot from './renders/renderFoot.tsx';
import renderHead from './renders/renderHead.tsx';
import renderNavItem from './renders/renderNavItem.tsx';
import renderNavList from './renders/renderNavList.tsx';
import nav from './static/nav.ts';

class SideBar extends React.Component<SideBarI['props'], SideBarI['state']> implements SideBarI {
    parent: SideBarI['parent'];

    constructor(props: SideBarI['props']) {
        super(props);
        this.state = {
            listsState: {},
            counters: {},
        };

        this.setContentHeight = this.setContentHeight.bind(this);
        this.socketHandler = this.socketHandler.bind(this);

        this.parent = React.createRef();
    }

    nav = nav;
    counters = {};

    renderHead = renderHead;
    renderContent = renderContent;
    renderFoot = renderFoot;
    renderNavItem = renderNavItem;
    renderNavList = renderNavList;

    saveUser = saveUser;
    getCorporation = getCorporation;
    getNotAdminCorporation = getNotAdminCorporation;
    getNav = getNav;
    setContentHeight = setContentHeight;
    setNavListPosition = setNavListPosition;
    listStateHandler = listStateHandler;
    setCounter = setCounter;
    getCounter = getCounter;
    getCounters = getCounters;
    setCounterStack = setCounterStack;
    checkList = checkList;
    companyListHandler = companyListHandler;

    socketHandler = socketHandler;

    componentDidMount(): void {
        this.saveUser();

        document.addEventListener('changeHeightWindow', this.setContentHeight);
        (document.addEventListener as CustomListenerT)('getSocketData', this.socketHandler);
    }

    componentWillUnmount(): void {
        document.removeEventListener('changeHeightWindow', this.setContentHeight);
        (document.removeEventListener as CustomListenerT)('getSocketData', this.socketHandler);
    }

    render() {
        const corporation = this.getCorporation();

        return (
            <div
                ref={this.parent}
                className={`v2sideBar _COL ${corporation?.isAdmin ? '_admin' : ''}`}
            >
                {this.renderHead()}
                {this.renderContent()}
                {this.renderFoot()}
            </div>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
        storePages: state.pages,
    };
}

export default connect(mapStateToProps)(SideBar);
