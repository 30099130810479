import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import handlerPopup from '@functions/handlerPopup';

import Animate from '../../Animate.jsx';
import AnimateChange from '../../AnimateChange.jsx';
import Icon from '../../Icon.jsx';

// import Filter from '../../Filter.jsx';

class WidgetFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handlerStateFilter() {
        const { name, filter, setFilter, filterQuery } = this.props;

        handlerPopup('filterPopup', {
            isShow: true,
            filter,
            filterQuery,
            name,
            callback: (result) => {
                if (setFilter) {
                    setFilter(result);
                }
            },
        });
    }

    render() {
        const { filter, className = '', children, filterQuery = [] } = this.props;
        const lenFilters = filterQuery.length;

        return (
            <div
                className={`widget__headActionsElem widget__headAction ${className} ${
                    children ? '_withChildren' : ''
                }`}
            >
                <div
                    className="widget__headActionInner _col _click"
                    onClick={({ target }) => {
                        if (filter) {
                            this.handlerStateFilter({ target });
                        }
                    }}
                >
                    {children}
                    <Animate className="widget__headActionCounter" isShow={lenFilters > 0}>
                        <AnimateChange
                            className="widget__headActionCounterInner _col"
                            prop={lenFilters}
                            type="_translateMedium"
                            isDisabled={lenFilters === 0}
                        >
                            {`${lenFilters}`}
                        </AnimateChange>
                    </Animate>
                    <i className="widget__headActionIcon">
                        <Icon name="widget-filter" />
                    </i>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(WidgetFilter);

WidgetFilter.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    filter: PropTypes.array,
    handlerFilter: PropTypes.object,
    getParent: PropTypes.func,
    centers: PropTypes.object,
    children: PropTypes.node,
    windowName: PropTypes.string,
};
