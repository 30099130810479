import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange';
import Avatar from '@components/avatar/Avatar';
import Icon from '@components/icon/Icon';
import Link from '@components/link/Link';
import getFormatPrice from '@functions/getFormatPrice';

import SideBarI from '../types';

type ParamsT = {
    corporation: ReturnType<SideBarI['getCorporation']>;
    withList: boolean;
    companyListHandler: SideBarI['companyListHandler'];
};

export default function Company({
    corporation,
    withList,
    companyListHandler,
}: ParamsT): React.ReactNode {
    return (
        <div className="v2sideBar__headWrapper">
            <div className="v2sideBar__headInner">
                <AnimateChange
                    className={`v2sideBar__headCompany ${withList ? '_withList' : ''}`}
                    renderKey={corporation?._id}
                    callback={({ params }: { params?: { width: number } }) => {
                        if (params?.width) {
                            document.body.style.setProperty(
                                `--sideBarHeadWidth`,
                                `${params.width}px`,
                            );
                        }
                    }}
                    styles={['width']}
                >
                    <>
                        <Link className="v2sideBar__headCompanyLogo" pageName="corporation">
                            <div className="v2sideBar__headCompanyLogoInner">
                                <Avatar
                                    image={corporation?.logo?.fullSrc}
                                    firstSymb={corporation?.fullName?.[0]?.toUpperCase()}
                                />
                            </div>
                            <div className="v2sideBar__headCompanyLogoAdmin">
                                <Icon name="admin" />
                            </div>
                        </Link>
                        <div
                            className="v2sideBar__headCompanyContent _COL"
                            onClick={(e) => {
                                if (withList) {
                                    e.stopPropagation();

                                    companyListHandler();
                                }
                            }}
                        >
                            <div className="v2sideBar__headCompanyTitle">
                                {corporation?.shortName || corporation?.fullName || '–'}
                            </div>
                            <div className="v2sideBar__headCompanyDescription">
                                <>Баланс: {getFormatPrice(corporation?.balance || 0)}₽</>
                            </div>
                        </div>
                    </>
                </AnimateChange>
            </div>
        </div>
    );
}
