import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Button from '@components/button/Button.tsx';
import WidgetCard from '@components/crm/widgetCard/WidgetCard.tsx';
import Icon from '@components/icon/Icon.tsx';
import logout from '@functions/logout.js';
import setSpacesInText from '@functions/setSpacesInText.ts';

import I from '../types.ts';

const renderContent: I['renderContent'] = function () {
    const { model, loadingKey, error, user } = this.state;

    return (
        <>
            <div className="v2popup__head _ROW _center">
                <div className="v2popup__title">Создание новой компании</div>
                {user?.corporations.length === 0 ? (
                    <>
                        <div className="v2popup__headActions">
                            <Button
                                className="_alertEmpty _minSize"
                                onClick={async () => {
                                    await logout();

                                    this.close();
                                }}
                            >
                                Выйти
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="v2popup__close _CLICK _top" onClick={this.close.bind(this)}>
                            <Icon name="popup-close" />
                        </div>
                    </>
                )}
            </div>
            <div className="v2popup__content _CENTER _NOSCROLL">
                <div className="v2popup__contentInner">
                    {model && (
                        <>
                            <WidgetCard
                                name="corporationMain"
                                model={model}
                                change={this.change.bind(this)}
                                getValue={this.getValue.bind(this)}
                                withHead={false}
                                className="_empty"
                                data={{ create: true }}
                                error={error}
                            />
                            <WidgetCard
                                name="corporationOther"
                                model={model}
                                change={this.change.bind(this)}
                                getValue={this.getValue.bind(this)}
                                withHead={false}
                                className="_empty"
                                data={{ create: true }}
                                error={error}
                            />
                        </>
                    )}
                </div>
            </div>
            <div className="v2popup__foot">
                <div className="v2popupFoot">
                    <div className="v2popupFoot__inner _COL">
                        <div className="v2popupFoot__button">
                            <Button
                                className="_main"
                                loading={loadingKey === 'save'}
                                onClick={this.save.bind(this)}
                            >
                                Сохранить
                            </Button>
                        </div>
                        <div className="v2popupFoot__info">
                            <div
                                className="v2popupFoot__infoText _min"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(
                                        'Отправляя данные, я подтверждаю своё согласие и согласие третьих лиц, в интересах которых я действую, на направление мне и им информационных и рекламных смс-сообщений, уведомлений, писем на указанный мной адрес электронной почты и/или номер мобильного телефона, в том числе с использованием сторонних сервисов.',
                                    ),
                                }}
                            />
                        </div>
                    </div>
                    <AnimateChange
                        className="v2popupFoot__error"
                        renderKey={error?.text}
                        styles={['height']}
                        paramsParent={{ width: true, height: 'auto' }}
                    >
                        <>{error?.text}</>
                    </AnimateChange>
                </div>
            </div>
        </>
    );
};

export default renderContent;
