import I from '../types.ts';

const listStateHandler: I['listStateHandler'] = function ({ name, isShow }) {
    this.setState((state) => {
        const newState = { ...state };
        const listsState = { ...newState.listsState };

        listsState[name] = isShow ?? !listsState[name];

        newState.listsState = listsState;

        return newState;
    });
};

export default listStateHandler;
