import I from '../types.ts';

const setCounter: I['setCounter'] = function ({ name, itemName, counter }) {
    return new Promise((resolve) => {
        this.setState((state) => {
            const newState = { ...state };
            const counters = { ...newState.counters };

            if (!counters[name]) {
                counters[name] = {};
            }

            if (!counters[name][itemName]) {
                counters[name][itemName] = 0;
            }

            counters[name][itemName] = counter;

            newState.counters = counters;

            return newState;
        }, resolve);
    });
};

export default setCounter;
