import React from 'react';

import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';

import I from '../types.ts';

const renderContent: I['renderContent'] = function () {
    const nav = this.getNav();
    const navLists = this.getNav(true);

    return (
        <div className="v2sideBar__content">
            <div className="v2sideBar__contentInner">
                <div className="v2sideBar__contentScroll _NOSCROLL">
                    <ListAbsoluteMain
                        className="v2sideBar__nav _ITEMS"
                        items={nav}
                        renderItem={this.renderNavItem.bind(this)}
                        classNameItem="v2sideBar__navItem"
                        prop="name"
                        paramsParent={{ width: true }}
                        styles={['height']}
                        itemParams={['offsetTop']}
                    />
                </div>

                {navLists.map((item) => this.renderNavList({ name: item.name }))}
            </div>
        </div>
    );
};

export default renderContent;
